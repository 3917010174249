.swiper {
  width: 80%;
  height: 100vh;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

.employee_show{
  min-height: 50px;
  background-color: white;
  box-shadow: 0 3px 15px 0 rgba(21, 38, 102, 0.05);
  min-width: 100px;
  display: flex;
  padding: 10px 10px 5px 10px;
  border-radius: 5px;
  gap: 30px;
  min-width: 140px;
  justify-content: space-between;
  position: relative;
}

.employee_main_div_main{
  height: 70vh;
  overflow: auto;
}

.employee_main_div{
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}


.employee_img{
  max-width: 35px;
  max-height: 35px;
}


.employee_main_div::-webkit-scrollbar {
  width: 5px;  /* Width of the scrollbar */
}

.employee_main_div::-webkit-scrollbar-track {
  background: #f1f1f1;  /* Light grey background */
  border-radius: 10px;
}

.employee_main_div::-webkit-scrollbar-thumb {
  background: #c4bcbc;  /* Darker grey */
  border-radius: 10px;
}

.employee_main_div::-webkit-scrollbar-thumb:hover {
  background: #555;  /* Even darker grey */
}
