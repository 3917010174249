.Button_Location {
  float: left;
  width: 175px;
  padding: 10px;
  text-align: center;
  margin: 2px 5px;
  box-shadow: 2px 2px 8px gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: none;

  -moz-box-shadow: inset 0px 1px 0px 0px #7a8eb9;
  -webkit-box-shadow: inset 0px 1px 0px 0px #7a8eb9;
  box-shadow: inset 0px 1px 0px 0px #7a8eb9;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #637aad),
    color-stop(1, #5972a7)
  );
  background: -moz-linear-gradient(top, #637aad 5%, #5972a7 100%);
  background: -webkit-linear-gradient(top, #637aad 5%, #5972a7 100%);
  background: -o-linear-gradient(top, #637aad 5%, #5972a7 100%);
  background: -ms-linear-gradient(top, #637aad 5%, #5972a7 100%);
  background: linear-gradient(to bottom, #637aad 5%, #5972a7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#637aad', endColorstr='#5972a7',GradientType=0);
  background-color: #637aad;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
  border-radius: 3px;
  display: inline-block;
  color: rgb(252, 252, 252);
  font-size: 14px;
  font-weight: bold;
  padding: 6px 11px;
  text-decoration: none;
  cursor: pointer;
  padding-left: 15px !important;
  text-shadow: 1px 1px 2px #7a8eb9;
}
